import { CSVLink } from "react-csv";
import moment from "moment";
import React, { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Input,
  Select,
  DatePicker,
  Card,
  Typography,
  Button,
  Skeleton,
  BackTop,
  Avatar,
  Space,
} from "antd";
import TableDrawer from "./TableDrawer";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import userColoredIcon from "../../assets/images/usercolored.svg";
import exporticon from "../../assets/images/Export.svg";
const { Option } = Select;
const { Title } = Typography;

const ApplicantsTable = () => {
  const { fetching, candidatesData } = useSelector((state) => state.data);
  const meta = useSelector((state) => state.data.meta);
  const [applicants, setApplicants] = useState([]);
  const [visible, setVisible] = useState(false);
  const [DrawerData, setDrawerData] = useState([]);
  const [filters, setFilters] = useState({
    queryText: "",
    country: "",
    date: "",
  });
  const [nameSort, setNameSort] = useState(null);
  const styles = useStyles();

  useEffect(() => {
    if (candidatesData) {
      setApplicants(candidatesData);
    }
  }, [candidatesData]);

  useEffect(() => {
    if (candidatesData && filters) {
      const _applicants = candidatesData
        .filter((f) =>
          f.fullName.toLowerCase().includes(filters.queryText.toLowerCase())
        )
        .filter((c) => c.country === filters.country || filters.country === "")
        .filter((d) =>
          moment(d.dateRegistered)
            .format("MMMM DD, YYYY")
            .includes(filters.date)
        );
      setApplicants(_applicants);
    }
    // eslint-disable-next-line
  }, [filters]);

  

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      width: 200,
      sortDirections: [],
      sorter: (a, b) =>
      a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase()),
      // eslint-disable-next-line
      sortOrder: "fullName" === "fullName" && nameSort,
      render: (text, record) => (
        <Space size="middle">
          <Avatar style={{ backgroundColor: "#ED6638" }} size="medium" gap={2}>
            {record.fullName
              .split(" ")
              .slice(0, 2)
              .map((i) => i.charAt(0).toUpperCase())}
          </Avatar>
          <p
            style={{
              fontFamily: "Regular",
              fontSize: 14,
              color: "#172B4D",
              paddingTop: 12,
              cursor: "pointer",
            }}
          >
            {record.fullName.split(" ").slice(0, 2).join(" ")}
          </p>
        </Space>
      ),
    },
    {
      title: "Email address",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      width: 150,
      render: (text, record) => <p className={styles.columnText}>{text}</p>,
    },
    {
      title: "Phone number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 150,
      render: (text, record) => <p className={styles.columnText}>{text}</p>,
    },
    {
      title: "Occupation",
      dataIndex: "occupation",
      key: "occupation",
      width: 150,
      render: (text, record) => <p className={styles.columnText}>{text}</p>,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 100,
      render: (text, record) => <p className={styles.columnText}>{text}</p>,
    },
    {
      title: "Date",
      dataIndex: "dateRegistered",
      key: "dateRegistered",
      width: 120,
      render: (text, record) => (
        <p className={styles.columnText}>
          {moment(record.dateRegistered).format("MMMM DD, YYYY")}
        </p>
      ),
    },
  ];

  //get a list of all countries
  let countries = [];

  try {
    candidatesData.forEach((value) => {
      countries.push(value.country);
    });
    //Remove duplicates
    countries = Array.from(new Set(countries));
  } catch (error) {}

  //remove null, empty strings
  let allCountries = countries.filter(Boolean);

  //see usage in dropdown list on line 254
  let options = allCountries.map((country) => (
    <option value={country}>{country}</option>
  ));

  const onClose = () => {
    setVisible(false);
  };

  const showDrawer = (id) => {
    setVisible(true);
    // send user id to display selected user data in drawer
    const Data = candidatesData.filter((x) => x.id === id);
    setDrawerData(Data[0]);
  };

  function handleNameSortChange(value) {
    value === "" ? setNameSort(null) : setNameSort(value);
  }

  const renderTableDrawer = () => {
    return (
      <TableDrawer visible={visible} onClose={onClose} data={DrawerData} />
    );
  };

  const renderDashBoardTopArea = () => {
    return (
      <Fragment>
      <Row>
        <Col lg={{ span: 2 }}>
          <Title level={4}>Overview</Title>
        </Col>
        <Col lg={{ span: 6, offset: 12 }}>
          <Row>
            <Col lg={{ span: 8, offset: 10 }}>
              {candidatesData ? (
                <CSVLink data={candidatesData} filename={"Applicants.csv"}>
                  <Button type="dashed" icon={<img src={exporticon} alt="" />}>
                    <span className={styles.text}>Export CSV</span>
                  </Button>
                </CSVLink>
              ) : (
                <Skeleton.Button active={fetching} shape="square" />
              )}
            </Col>
          </Row>
        </Col>
        <Col className={styles.flexEnd} lg={{ span: 4 }}>
          <DatePicker
            style={{ verticalAlign: "top" }}
            bordered={true}
            onChange={(date, dateString) => {
              if (dateString === "") {
                setFilters({ ...filters, date: "" });
              } else {
                setFilters({
                  ...filters,
                  date: moment(dateString).format("MMMM DD, YYYY"),
                });
              }
            }}
          />
        </Col>
      </Row>
      <Row style={{ paddingTop: 20 }}>
        <Card
          bodyStyle={{
            width: 187,
            height: 87,
            boxShadow:
              "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
            padding: 15,
          }}
        >
          <Row>
            <Col
              span={16}
              style={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
            >
              Total Applicants
            </Col>
            <Col span={8}>
              <img style={{ color: "#93278F" }} src={userColoredIcon} alt="" />
            </Col>
            <p className={styles.metaText}>{meta}</p>
          </Row>
        </Card>
      </Row>
    </Fragment>
    );
  }

  const renderSearchBarAndFilters = () => {
    return (
      <Fragment>
        <Row style={{ marginTop: 20 }}>
        <Col lg={{ span: 1 }}>
          <p style={{ color: "#6B778C", fontSize: 12, fontFamily: "Regular" }}>
            Search
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 16 }}>
          <Input.Search
            placeholder="Search..."
            enterButton
            onChange={(e) => {
              setFilters({ ...filters, queryText: e.target.value });
            }}
          />
        </Col>
        <Col className={styles.flexEnd} lg={{ span: 4 }}>
          <Select
            defaultValue="All countries"
            style={{ width: 157 }}
            onChange={(value) => {
              if (value === "") {
                setFilters({ ...filters, country: "" });
              } else {
                setFilters({ ...filters, country: value });
              }
            }}
          >
            <option value="">All countries</option>
            {options}
          </Select>
        </Col>
        <Col className={styles.flexEnd} lg={{ span: 4 }}>
          <Select
            style={{ width: 157 }}
            defaultValue="Sort Name A-Z"
            onChange={handleNameSortChange}
          >
            <Option value="ascend">Ascending</Option>
            <Option value="descend">Descending</Option>
            <Option value="">Default</Option>
          </Select>
        </Col>
      </Row>
      </Fragment>
    )
  }

  const renderDataTable = () => {
    return (
      <Fragment>
         <Row style={{ marginTop: 8 }}>
        <BackTop style={{ marginBottom: 45 }}>
          <Button type="primary" shape="rounded">
            Top
          </Button>
        </BackTop>
        <Col lg={{ span: 24 }}>
          <Table
            pagination={{
              total: applicants.length,
              defaultPageSize: 20,
              showLessItems: true,
            }}
            className={styles.tableRow}
            loading={fetching}
            rowKey={(record) => record.id}
            dataSource={applicants}
            columns={columns}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  showDrawer(record.id);
                },
              };
            }}
          />
        </Col>
      </Row>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {renderTableDrawer()}
      {renderDashBoardTopArea()}
      {renderSearchBarAndFilters()}
      {renderDataTable()}
    </Fragment>
  );
};

const useStyles = makeStyles({
  tableRow: {
    border: "2px solid #E6E6E6",
    verticalAlign: "baseline",
  },
  text: {
    fontSize: "14px",
    fontWeight: "bold",
    boxSizing: "border-box",
    paddingLeft: "10px",
  },
  card: {
    width: 187,
    height: 87,
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    padding: 15,
  },
  metaText: {
    marginTop: "5px",
    fontSize: "24px",
    fontFamily: "Bold",
    color: "#151C2F",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  flexStart: {
    marginRight: "118px",
  },
  columnText: {
    fontFamily: "Regular",
    fontSize: 14,
    color: "#172B4D",
    opacity: 0.7,
    paddingTop: 12,
    cursor: "pointer",
  },
});

export default ApplicantsTable;
