import moment from "moment";

export default class AuthStore {
  static authenticated() {
    const token = localStorage.getItem("token");
    const expiryDate = localStorage.getItem("tokenExpiryDate");
    return token && moment() < moment(expiryDate);
  }

  static setToken(token, expiryDate) {
    localStorage.setItem("token", token);
    localStorage.setItem("tokenExpiryDate", expiryDate);
  }

  static removeToken() {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiryDate");
    localStorage.removeItem("user");
  }
}
