import React from "react";
import { Layout, Row, Col, Typography } from "antd";
import { makeStyles } from "@material-ui/styles";
import logo from "../assets/images/womentechers.svg";
import LoginForm from "./LoginForm";
const { Sider, Content } = Layout;
const { Title } = Typography;

const LoginPage = () => {
  const style = useStyles();
  return (
    <Layout>
      <Sider className={style.side}>
        <div className={style.logo}>
          <img src={logo} alt="" />
        </div>
      </Sider>
      <Content>
        <div className={style.center}>
          <div className={style.mainBox}>
            <Row>
              <Col lg={{span: 9}}>
                <Title className={style.welcome}>Welcome Back,</Title>
                <p style={{ paddingLeft: 5, fontFamily: "Regular", fontSize:16 }}>Login to your account</p>
              </Col>
              <Col lg={{span:12, offset:3}}>
                <div className={style.formbg}>
                  <div>
                    <LoginForm />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

const useStyles = makeStyles({
  center: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F7F7F7",
    height: "100vh",
  },
  side: {
    backgroundColor: "#F7F7F7",
    width: "150px",
  },
  logo: {
    padding: "60px",
  },
  hero: {
    textAlign: "left",
  },
  formbg: {
    textAlign: "center",
    boxSizing: "border-box",
    height: "380px",
    width: "467px",
    border: "1px solid #EBEBEB",
    borderRadius: "3px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 6px 0 rgba(0,0,0,0.08)",
    padding: "40px 50px 60px",
  },
  mainBox: {
    marginLeft: "60px",
    marginTop: "100px",
  },
  welcome: {
    color: "#212B36",
    fontFamily: "Bold",
    fontWeight: "bold",
    fontSize: "36px",
    height: "42px",
    letterSpacing: "0",
  },
  loginText: {
    fontSize: "16px",
    color: "#7A869A",
  },
});

export default LoginPage;
