import React, { useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { Layout } from "antd";
import SideNav from "./Layout/SideNav";
import ApplicantsTab from "./applicantsTab/ApplicantsTab";
import ReportsTab from "./reportsTab/ReportsTab";
import { dataActions } from "../_services/dataService";
import { useDispatch } from "react-redux";
import AuthStore from "../_services/authStoreService";
import Navbar from "./Layout/Navbar";
const { Content } = Layout;


const Dashboard = () => {
  const style = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(dataActions.fetchCandidates());
  
  }, [dispatch])

  useEffect(() => {
    if (!AuthStore.authenticated()){
      history.push('/');
     }
 },)

  return (
    <Fragment>
      <Navbar/>
      <Layout>
      <SideNav />
      <Layout style={{marginLeft: 200}}>
        <Content className={style.content}>
          <Switch>
            <Redirect exact from="/dashboard" to="/dashboard/applicants" />
            <Route path="/dashboard/applicants" render={() => <ApplicantsTab/>}/>
            <Route path="/dashboard/reports" render={() => <ReportsTab />}/>
          </Switch>
        </Content>
      </Layout>
    </Layout>
    </Fragment>
   
  );
};

const useStyles = makeStyles({
  content: {
    padding: "40px 50px",
    minHeight: "734px",
    background: "#FDFAFD",
    marginTop: 60
  },
});

export default Dashboard;
