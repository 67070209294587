import { makeStyles } from "@material-ui/styles";
import usericon from "../../assets/images/Users.svg";
import logouticon from "../../assets/images/logout.svg";
import reportsicon from "../../assets/images/reports.svg";
import React from "react";
import { Layout, Menu} from "antd";
import { Link } from "react-router-dom";
import { userActions } from "../../_services";
import { useDispatch } from "react-redux";
import cx from 'classnames';
const { Sider } = Layout;


const SideNav = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  
  return (
    <Sider className={styles.side}>
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        style={{ height: "100%", background: "#FDFAFD", paddingTop: 20 }}
      >
        <Menu.Item
          key="1"
          icon={<img className={styles.img} alt="" src={usericon} />}
        >
          <span style={{fontFamily:"Bold"}}>Applicants</span>
          <Link to="/dashboard/applicants" />
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={<img className={styles.img} alt="" src={reportsicon} />}
        >
          <span style={{fontFamily:"Bold"}}>Reports</span>
          <Link to="/dashboard/reports" />
        </Menu.Item>
        <Menu.Item
          style={{marginTop:"60vh"}}
          onClick={()=> dispatch(userActions.logUserOut())}
          key="3"
          icon={<img className={cx(styles.img)} alt="" src={logouticon} />}
        >
          <span className={styles.logout}>Logout</span>
          <Link to="/" />
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

const useStyles = makeStyles({
  img: {
    padding: "10px",
    fontSize: "14px",
  },
  logout: {
    fontFamily:"Bold", 
    color: "#151C2F", 
    cursor: "pointer", 
    opacity: "0.7"
  },
  side: {
    overflow: 'auto',
    height: '100vh',
    position: 'fixed',
    top: 65,
  }
});

export default SideNav;
