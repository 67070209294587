import React, { Fragment } from "react";
import { Row, Col, Skeleton } from "antd";
import DailyApplicationCharts from "./DailyApplicationCharts";
import { makeStyles } from "@material-ui/styles";
import DonutCharts from "./DonutCharts";
import BarCharts from "./BarCharts";
import { useSelector } from "react-redux";

const ReportCharts = () => {
  const styles = useStyles();
  const { comparisonRateChart, fetching } = useSelector((state) => state.chart);

  let ageRange = [];
  let aRKeys = [];
  const aRColours = ['#827C46','#2D99FF','#6D5BC6', '#61B9AF','#DFE4E8'];

  let digitalSKills = [];
  let dSKeys = [];
  const dsColours = ['#827C46', '#61B9AF','#6D5BC6'];

  let computerSkills = [];
  let cSKeys = [];
  const cSColours = ['#2D99FF','#6D5BC6'];

  let stableInternet = [];
  let sIKeys = [];
  const sIColours = ['#6D5BC6','#61B9AF'];

  let hasComputer = [];
  let hCKeys = [];
  const hCColours = ['#6D5BC6','#827C46'];

  let educationQualification = [];
  let eQKeys = [];
  const eQColours = ['#827C46','#2D99FF','#6D5BC6', '#61B9AF','#DFE4E8'];
  
  let validEQ = ['Secondary school', 'Diploma', 'University Degree', 'Masters Degree', 'PhD']
  try {

    const eQChart = Object.keys(comparisonRateChart[5].chartData).reduce((chart, currentKey) => {
       if(validEQ.includes(currentKey)){
         return {
          ...chart,
          [currentKey]: comparisonRateChart[5].chartData[currentKey]
         }
       } else {
         return {
           ...chart,
         }
       }
    },{})

    ageRange = Object.values(comparisonRateChart[0].chartData);
    aRKeys = Object.keys(comparisonRateChart[0].chartData);

    digitalSKills = Object.values(comparisonRateChart[1].chartData);
    dSKeys = Object.keys(comparisonRateChart[1].chartData);

    computerSkills = Object.values(comparisonRateChart[2].chartData);
    cSKeys = Object.keys(comparisonRateChart[2].chartData);

    stableInternet = Object.values(comparisonRateChart[3].chartData);
    sIKeys = Object.keys(comparisonRateChart[3].chartData);

    hasComputer = Object.values(comparisonRateChart[4].chartData);
    hCKeys = Object.keys(comparisonRateChart[4].chartData);

    educationQualification = Object.values(eQChart);
    eQKeys = Object.keys(eQChart)
   
  } catch (error) {}
  
  
  return (
    <Fragment>
      <Row className={styles.topChart}>
        <Col lg={{span:24}}>
         <Skeleton loading={fetching}>
            <DailyApplicationCharts /> 
         </Skeleton>
        </Col>
      </Row>
        <Row className={styles.midRow}>
          <Col className={styles.midCol} lg={{span:11}} >
            <Skeleton loading={fetching}>
                <DonutCharts
                title="Age Range"
                data={ageRange}
                categories={aRKeys}
                colours={aRColours}
            />
            </Skeleton>
          </Col>
          <Col className={styles.midCol} lg={{span:11, offset:2}}>
            <Skeleton loading={fetching}>
                <DonutCharts
                title="Digital skill competency"
                data={digitalSKills}
                categories={dSKeys}
                colours={dsColours}
            />
            </Skeleton>
          </Col>
        </Row>
        <Row className={styles.midRow}>
          <Col className={styles.midCol} lg={{span:11}}>
            <Skeleton loading={fetching}>
               <DonutCharts
                title="Stable Internet"
                data={stableInternet}
                categories={sIKeys}
                colours={sIColours}
            /> 
            </Skeleton>
          </Col>
          <Col className={styles.midCol} lg={{span:11, offset:2}}>
            <Skeleton loading={fetching}>
                <DonutCharts
                title="Do you have a computer"
                data={hasComputer}
                categories={hCKeys}
                colours={hCColours}
            />
            </Skeleton>
         
          </Col>
        </Row>
        <Row className={styles.midRow}>
          <Col className={styles.midCol} lg={{span:11}}>
              <Skeleton loading={fetching}>
                    <DonutCharts
                    title="Computer Related Skills"
                    data={computerSkills}
                    categories={cSKeys}
                    colours={cSColours}
                /> 
              </Skeleton>
          </Col>
          <Col className={styles.midCol} lg={{span:11, offset:2}}>
              <Skeleton loading={fetching}>
                  <DonutCharts
                    title="Educational Qualification"
                    data={educationQualification}
                    categories={eQKeys}
                    colours={eQColours}
                /> 
              </Skeleton>
          </Col>
        </Row>
      <Row className={styles.buttomChart}>
        <Col lg={{span:24}}>
          <Skeleton loading={fetching}>
            <BarCharts />
          </Skeleton>
        </Col>
      </Row>
    </Fragment>
  );
};

const useStyles = makeStyles({
  topChart: {
    marginTop: "20px",
    padding: "20px",
    height: "334px",
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    backgroundColor: "white",
  },
  buttomChart: {
    marginTop: "20px",
    padding: "20px",
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    backgroundColor: "white",
  },
  midRow: {
    marginTop: "20px",
  },
  midCol: {
    height: 270,
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    padding: "10px",
    backgroundColor: "white",
  },
});

export default ReportCharts;
