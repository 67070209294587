import React from 'react'
import Chart from "react-apexcharts";

const DonutCharts = ({title, data, categories, colours}) => {
    const series =  data
    const options = {
        title: {
            text:  title,
            align: "left"
        },
        chart: {
            type: "donut",
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: true,
                  selection: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  pan: false,
                  reset: false,
                },
            },    
        },
        responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
        labels: categories,
        colors: colours,
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '10px',
                fontFamily: 'Regular',
                color: 'black'
            },
            dropShadow: {
                enabled: true
            },
            offset: 15,
        },
        
    }
    return (
        <div>
           <Chart options={options} series={series} type="donut" height={230} />
        </div>
    )
}

export default DonutCharts;
