import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { Layout, Row, Col, Typography, Form, Input, Button } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { makeStyles } from "@material-ui/styles";
import logo from "../assets/images/womentechers.svg";
import { userActions } from "../_services";
import { useDispatch, useSelector } from "react-redux";
const { Sider, Content } = Layout;
const { Title } = Typography;


const ResetPassword = () => {
  const history = useHistory();
  const {loading, passwordIsReset} = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const style = useStyles();

  useEffect(() => {
    if(passwordIsReset === true){
      history.push("/");
    }
  },)

  //retrieve email stored in local storage from forgot-password screen
  let email = localStorage.getItem("resetMail");

  const onFinish = async (values) => {
    const {newPassword} = values
    dispatch(userActions.resetPassword(email, newPassword));
  };

  return (
    <Layout>
      <Sider className={style.side}>
        <div className={style.logo}>
          <img src={logo} alt="" />
        </div>
      </Sider>
      <Content>
        <div className={style.center}>
          <div className={style.mainBox}>
            <Row>
              <Col lg={{span: 12}}>
                <Title className={style.welcome}>Reset Password</Title>
                <p style={{fontFamily: "Regular", fontSize:16 }}>
                 Enter your new password<br/>
                </p>
              </Col>
              <Col lg={{span: 12}}>
                <div className={style.formbg}>
                <div>
                    <Form
                    name="reset_password"
                    layout="vertical"
                    className={style.form}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    hideRequiredMark={true}
                    >
                    <Form.Item
                        label="New Password"
                        name="newPassword"
                        rules={[{ required: true, message: "Please input your new Password!" }]}>
                        <Input.Password
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        type="password"
                        size="large"
                        placeholder="********"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className={style.formButton} loading={loading}>
                         Reset
                        </Button>
                    </Form.Item>
                    <Form.Item>
                      <a className={style.formForgot} href="/">
                      Login
                      </a>
                    </Form.Item>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

const useStyles = makeStyles({
  center: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F7F7F7",
    height: "100vh",
  },
  side: {
    backgroundColor: "#F7F7F7",
    width: "150px",
  },
  logo: {
    padding: "60px",
  },
  hero: {
    textAlign: "left",
  },
  formbg: {
    textAlign: "center",
    boxSizing: "border-box",
    height: "351px",
    width: "467px",
    border: "1px solid #EBEBEB",
    borderRadius: "3px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 6px 0 rgba(0,0,0,0.08)",
    padding: "50px",
  },
  mainBox: {
    marginLeft: "60px",
    marginTop: "20px",
  },
  welcome: {
    color: "#212B36",
    fontFamily: "Bold",
    fontWeight: "bold",
    fontSize: "36px",
    height: "42px",
    letterSpacing: "0",
  },
  loginText: {
    fontSize: "16px",
    color: "#7A869A",
  },
  form: {
    maxWidth:"370px",
    height: "40px"
    
  },
  formForgot: {
    float: "right"
  },
  formButton: {
    width: "100%",
    height: "40px"
  },
});

export default ResetPassword;
