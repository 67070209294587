import React, { useEffect } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { makeStyles } from "@material-ui/styles";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { userActions } from "../_services";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthStore from "../_services/authStoreService";


const LoginForm = () => {

  
  const history = useHistory();
  const styles = useStyles();
  const dispatch = useDispatch();
  const {loading} = useSelector(state => state.auth);
  const [form] = Form.useForm();

  
  useEffect(() => {
     if (AuthStore.authenticated()){
      history.push('/dashboard');
     }
  },)
  
  
  const onFinish = async (values) => {
    const {email, password} = values
    dispatch(userActions.login(email, password));
  };

  
  return (
    <Form
      form={form}
      name="normal_login"
      layout="vertical"
      className={styles.form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      hideRequiredMark={true}
    >
      <Form.Item
        label="Email address"
        name="email"
        rules={[{ required: true, message: "Please input your Email!" }]}
      >
        <Input
          type="email"
          size="large"
          placeholder="seguneromosele@gmail.com"
        />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your Password!" }]}
      >
        <Input.Password
          size="large"
          iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          type="password"
          placeholder="********"
        />
      </Form.Item>
      
      <div className={styles.formFlex}>
      <Form.Item name="remember" valuePropName="checked" style={{textAlign:"left"}} noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
        <Form.Item>
        <a className={styles.formForgot} href="/forgot-password">
          Forgot password
        </a>
       </Form.Item>
      </div>
      <Form.Item>
        <Button type="primary" htmlType="submit" className={styles.formButton} loading={loading}>
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

const useStyles = makeStyles({
  form: {
      maxWidth:"370px",
      
  },
  formForgot: {
      float: "right"
  },
  formButton: {
      width: "100%",
      height: "40px"
  },
  formFlex:{
    display: "flex", 
    justifyContent: "space-between",
  }
})

export default LoginForm;
