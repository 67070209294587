import React, { Fragment } from "react";
import ApplicantsTable from "./ApplicantsTable";

const ApplicantsTab = () => {
  return (
    <Fragment>
      <ApplicantsTable/>
    </Fragment>
  );
};

export default ApplicantsTab;
