const { createSlice } = require("@reduxjs/toolkit");

const chartSlice = createSlice({
  name: "chart",
  initialState: { fetching: false },
  reducers: {
    fetchingChart: (state) => {
      state.fetching = true;
    },
    fetchApplicationRateChartSuccess: (state, action) => {
      state.applicationRateChart = action.payload;
    },
    fetchComparisonChartSuccess: (state, action) => {
      state.comparisonRateChart = action.payload;
    },
    fetchPopularityChartSuccess: (state, action) => {
      state.popularityRateChart = action.payload;
      state.fetching = false;
    },
    fetchChartFailure: (state) => {
      state.fetching = false;
    },
  },
});

export const {
  fetchChartFailure,
  fetchPopularityChartSuccess,
  fetchComparisonChartSuccess,
  fetchApplicationRateChartSuccess,
  fetchingChart,
} = chartSlice.actions;

export default chartSlice.reducer;
