const { createSlice } = require("@reduxjs/toolkit");

const dataSlice = createSlice({
    name:'data',
    initialState: { fetching: false},
    reducers: {
         fetchingData : (state) => {
            state.fetching = true;
         },
         fetchDataSuccess: (state, action) => {
            state.candidatesData = action.payload;
            state.fetching = false;
         },
         fetchDataFailure: (state) => {
            state.fetching = false
         },
         fetchMeta: (state, action) => {
            state.meta = action.payload;
            state.fetching = false;
         }
    }
})

export const {fetchingData, fetchDataSuccess, fetchDataFailure, fetchMeta} = dataSlice.actions;

export default dataSlice.reducer