import {
  loginSuccess,
  loginFailure,
  loginRequest,
  logout,
  passwordResetRequest,
  passwordResetSuccess,
  passwordResetFailure,
  passwordIsReset
} from "../auth/authSlice";
import { message } from "antd";
import AuthStore from "./authStoreService";

const errorMsg = (errorMessage) => {
  message.error(errorMessage , 5 );
};

const successMsg = (successMessage) => {
  message.success(successMessage , 5 );
};

const baseURL = process.env.REACT_APP_API_BASE_URL;

const login = (email, password) => async (dispatch) => {
  dispatch(loginRequest());
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };
  
  try {
    const fetchdata = await fetch(`${baseURL}/auth/sign-in`, requestOptions);
    const response = await fetchdata.json();
    const { success, data } = response;

    if (success) {
      const { token, expiresIn } = data.token;
      await dispatch(loginSuccess(data.email));
      localStorage.setItem("user", data.email);
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      AuthStore.setToken(token, expiresIn);
    } else {
      dispatch(loginFailure());
      errorMsg("Incorrect credentials or network timeout. Please try again");
    }
  } catch (error) {
    dispatch(loginFailure());
    errorMsg("Something went wrong, please try again or contact admin");
  }
  
};

const logUserOut = () => async (dispatch) => {
  dispatch(logout());
  AuthStore.removeToken();
};

const forgotPassword = (email) => async (dispatch) => {
  //dispatch request start
  dispatch(passwordResetRequest());
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };
  try {
    const fetchdata = await fetch(`${baseURL}/auth/forgot-password/${email}`, requestOptions);
    const response = await fetchdata.json();

    const { success } = response;
    if (success) {
      await dispatch(passwordResetSuccess());
      successMsg("A code has been sent to your email");
      localStorage.setItem("resetMail", email);
    } else {
      dispatch(passwordResetFailure());
      errorMsg("Something went wrong, please try again"); 
    }
  } catch (error) {
    dispatch(passwordResetFailure());
    errorMsg("Something went wrong, please try again");
  }
};

    const resetPassword = (email, newPassword) => async (dispatch) => {
      //dispatch request start
      dispatch(passwordResetRequest());

      const urlObject = new URL(window.location);
      const code = urlObject.searchParams.get('code');
      
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({code, email, newPassword}),
      };
      
      try {
        const fetchdata = await fetch(`${baseURL}/auth/reset-password`, requestOptions);
        const response = await fetchdata.json();

        const { success, message } = response;
        if (success) {
        dispatch(passwordResetSuccess());
        await dispatch(passwordIsReset());
        successMsg("You can now proceed to login with your new password");
        
        } else {
          dispatch(passwordResetFailure());
          errorMsg("Something went wrong, please try again");
          console.log(message);
        }
      } catch (error) {
        dispatch(passwordResetFailure());
        errorMsg("Something went wrong, please try again");
      }
    
    };

export const userActions = {
  login,
  logUserOut,
  forgotPassword,
  resetPassword,
};
