import React from 'react'
import Chart from "react-apexcharts";
import { useSelector } from 'react-redux';

const BarCharts = () =>  {
    const { popularityRateChart } = useSelector((state) => state.chart);

    //Process data to be suitable for use in the DailyApplicationCharts
    //Number of registered users per day is to be displayed on the chart
    let registeredUsers = [];
    //let platforms = []; Platforms(categories) had to be set manually because of text overflow see line 30

    try {
        popularityRateChart.forEach(value => {
        registeredUsers.push(value.sourceCount);
        //platforms.push(value.source);
    });
    } catch (error) {
        
    }

    const series = [
        {
            name: "Applicants",
            data: registeredUsers
        }
     ]
     const options = {
         xaxis: {
            categories: [['Microsoft', 'social media', 'pages'],
                        ['Tech4dev', 'social media', 'pages'],
                        ['Tech4dev', 'website'],
                        'Facebook',
                        'Instagram',
                        'Twitter',
                        'LinkedIn',
                        'Blogs',
                        'Others'
                        ],
            // labels: {
            //     rotate: -30
            // }
         },
         title: {
            text:  "How did you hear about us",
            align: "left"
        },
        colors: ['#61B9AF'],
        chart: {
            height: "300",
            type: "bar",
            zoom: {
            enabled: false
            }
        },
        plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: 'top'
              },
             
            }
        },
        dataLabels : {
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
              }
        }
     }

    return (
        <div>
          <Chart options={options} series={series} type="bar" height={300}/>  
        </div>
    )
}

export default BarCharts
