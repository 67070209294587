import React from "react";
import { Layout, Row, Col, Typography, Button } from "antd";
import { makeStyles } from "@material-ui/styles";
import logo from "../assets/images/womentechers.svg";
import { Link, useHistory } from "react-router-dom";
const { Sider, Content } = Layout;
const { Title } = Typography;

const PasswordResetDone = () => {
  const history  = useHistory();
  const style = useStyles();

  //retrieve email stored in local storage from forgot-password screen
  let email = localStorage.getItem("resetMail");

  return (
    <Layout>
      <Sider className={style.side}>
        <div className={style.logo}>
          <img src={logo} alt="" />
        </div>
      </Sider>
      <Content>
        <div className={style.center}>
          <div className={style.mainBox}>
            <Row>
              <Col lg={{span: 12}}>
                <Title className={style.welcome}>Reset Link Sent!</Title>
                <p style={{ fontFamily: "Regular", fontSize: 16 }}>
                  Check your email {email}
                  <br />
                  and follow the instructions
                  <br />
                </p>
              </Col>
              <Col lg={{span: 12}}>
                <div className={style.formbg}>
                  <div>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={style.formButton}
                      onClick={() => history.push("/")}
                    >
                      Done
                      <Link to="/"/>
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

const useStyles = makeStyles({
  center: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F7F7F7",
    height: "100vh",
  },
  side: {
    backgroundColor: "#F7F7F7",
    width: "150px",
  },
  logo: {
    padding: "60px",
  },
  hero: {
    textAlign: "left",
  },
  formbg: {
    textAlign: "center",
    boxSizing: "border-box",
    height: "259px",
    width: "467px",
    border: "1px solid #EBEBEB",
    borderRadius: "3px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 6px 0 rgba(0,0,0,0.08)",
    padding: "50px",
  },
  mainBox: {
    marginLeft: "60px",
    marginTop: "20px",
  },
  welcome: {
    color: "#212B36",
    fontFamily: "Bold",
    fontWeight: "bold",
    fontSize: "36px",
  },
  loginText: {
    fontSize: "16px",
    color: "#7A869A",
  },
  form: {
    maxWidth: "370px",
  },
  formForgot: {
    float: "right",
  },
  formButton: {
    width: "100%",
    height: "40px"
  },
});

export default PasswordResetDone;
