import React from "react";
import { Layout } from "antd";
import logo from "../../assets/images/womentechers.svg";
import { makeStyles } from "@material-ui/styles";
const { Header } = Layout;

const Navbar = () => {
  const style = useStyles();
  return (
    <Header className={style.header}>
      <img
        className={style.logo}
        alt=""
        src={logo}
      />
    </Header>
  );
};

const useStyles = makeStyles({
  logo: {
    display: "flex",
    alignContent: "flex-start",
    height: "40px",
    width: "40px",
    marginLeft: "10px",
  },
  header: {
    padding: "10px", 
    background: "#93278F", 
    position: 'fixed', 
    zIndex: 1, 
    width: '100%'
  }
});

export default Navbar;
