import usercoloredicon from '../../assets/images/usercolored.svg'
import React, { Fragment, useEffect } from "react";
import { Row, Col, Card, Typography } from "antd";
import { dataActions } from '../../_services';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
const { Title } = Typography;

const ReportsMainArea = () => {
  const style = useStyles();
  const meta = useSelector((state) => state.data.meta);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dataActions.fetchChartsData());
  }, [dispatch])


  return (
    <Fragment>
      <Row>
        <Col lg={{span: 16}} style={{ textAlign: "left" }}>
          <Title level={4}>Reports</Title>
        </Col>
      </Row>
      <Row style={{ paddingTop: 20 }}>
        <Card
          bodyStyle={{
            width: 187,
            height: 87,
            boxShadow:
              "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
            padding: 15
          }}
        >
          <Row>
            <Col span={16} className={style.applicants}>Total Applicants</Col>
            <Col span={8} >
            <img style={{color:'#93278F'}} src={usercoloredicon} alt="" />
            </Col>
            <p className={style.meta}>{meta}</p>   
           </Row>
        </Card>
      </Row>
    </Fragment>
  );
};

const useStyles = makeStyles({
  meta: {
    marginTop: "5px", 
    fontSize: "24px", 
    fontFamily:"Bold", 
    color:"#151C2F"
  },
  applicants: {
    textAlign: "left", 
    fontSize: "12px", 
    fontWeight:"bold" 
  },
})

export default ReportsMainArea;
