import React from "react";
import "./App.css";
import "antd/dist/antd.less";
import { Layout } from "antd";
import { Switch, Route } from "react-router-dom";
import LoginPage from "./components/LoginPage";
import Dashboard from "./components/Dashboard";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import PasswordResetDone from "./components/PasswordResetDone";

function App() {
  return (
    <div>
      <Layout>
        <Switch>
          <Route exact path="/" component={LoginPage}/>
          <Route path="/forgot-password" component={ForgotPassword}/>
          <Route path="/reset-password" component={ResetPassword}/>
          <Route path="/password-reset-done" component={PasswordResetDone}/>
          <Route path="/dashboard" component={Dashboard}/>
        </Switch>
      </Layout>
    </div>
  );
}

export default App;
