import React from "react";
import {Drawer, Row, Col, Divider} from "antd";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";


const TableDrawer = ({ onClose, visible, data}) => {
  const styles = useStyles();
  return (
    <Drawer
      width={669}
      placement="right"
      closable={true}
      onClose={onClose}
      visible={visible}
    >
      <p className={styles.header}>Applicant's Details</p>
      <Divider style={{color: "#637381"}} className={styles.largeSpace}/>
      <p className={styles.title}>Bio-Data</p>
      <Row className={styles.largeSpace}>
        <Col span={12}>
          
          <p className={styles.subTitle}>Name<br/>
          <span className={styles.data}>{data.fullName}</span></p>

          <p className={styles.subTitle}>Phone Number<br/>
          <span className={styles.data}>{data.phoneNumber}</span></p>

          <p className={styles.subTitle}>Marital status<br/>
          <span className={styles.data}>{data.married ? 'Married' : 'Single'}</span></p>
          
        </Col>
        <Col span={12}>

          <p className={styles.subTitle}>Email<br/>
          <span className={styles.data}>{data.email}</span></p>

          <p className={styles.subTitle}>Age<br/>
          <span className={styles.data}>{data.ageRange}</span></p>

          <p className={styles.subTitle}>Kids<br/>
          <span className={styles.data}>{data.kids ? 'Yes' : 'No'}</span></p>
          
        </Col>
      </Row>
      <Divider style={{color: "#637381"}} className={styles.largeSpace}/>
      <p className={styles.title}>Contact Information</p>
      <Row className={styles.largeSpace}>
        <Col span={12}>
          
          <p className={styles.subTitle}>Address 1<br/>
          <span className={styles.data}>{data.address1}</span></p>

          <p className={styles.subTitle}>Country<br/>
          <span className={styles.data}>{data.country}</span></p>

          <p className={styles.subTitle}>City<br/>
          <span className={styles.data}>{data.city}</span></p>
          
        </Col>
        <Col span={12}>

          <p className={styles.subTitle}>Address 2<br/>
          <span className={styles.data}>{data.address2}</span></p>

          <p className={styles.subTitle}>State<br/>
          <span className={styles.data}>{data.state}</span></p>

          <p className={styles.subTitle}>Date of Registration<br/>
          <span className={styles.data}>{moment(data.dateRegistered).format("MMMM DD, YYYY")}</span></p>
          
        </Col>
      </Row>
      <Divider style={{color: "#637381"}} className={styles.largeSpace}/>
      <p className={styles.title}>General Information</p>
      <Row className={styles.largeSpace}>
        <Col span={12}>
          
          <p className={styles.subTitle}>Highest Qualification<br/>
          <span className={styles.data}>{data.highestQualification}</span></p>

          <p className={styles.subTitle}>Access to computer<br/>
          <span className={styles.data}>{data.personalDevice ? 'Yes' : 'No'}</span></p>

          <p className={styles.subTitle}>Access to internet<br/>
          <span className={styles.data}>{data.internetAccess ? 'Yes' : 'No'}</span></p>

          <p className={styles.subTitle}>How did you hear about us<br/>
          <span className={styles.data}>{data.howYouheard}</span></p>
          
        </Col>
        <Col span={12}>
          <div>
            <p className={styles.subTitle}>Course of study<br/>
          <span className={styles.data}>{data.courseOfStudy}</span></p>
          </div>
          

          <p className={styles.subTitle}>Occupation<br/>
          <span className={styles.data}>{data.occupation}</span></p>

          <p className={styles.subTitle}>Digital Competence<br/>
          <span className={styles.data}>{data.digitalCompetency}</span></p>
          
        </Col>
      </Row>
    </Drawer>
  );
};

const useStyles = makeStyles({
  header : {
    height: "28px",
    color: "#212B36",
    fontFamily: "Regular",
    fontSize: "20px",
    letterSpacing: "0",
    lineHeight: "28px",
    marginBottom: "17px"
  },
  title : {
    height: "16px",
    color: "#637381",
    fontFamily: "Regular",
    fontSize: "18px",
    letterSpacing: "0",
    lineHeight: "16px",
  },
  subTitle : {
    height: "16px",
    color: "#637381",
    fontFamily: "Bold",
    fontSize: "12px",
    letterSpacing: "0",
    lineHeight: "16px",
    marginBottom: "36px",
    
  },
  data : {
    height: "20px",
    color: "#212B36",
    fontFamily: "Regular",
    fontSize: "14px",
    letterSpacing: "0",
  },
  largeSpace :{
    marginBottom: "24px"
  },
  smallSpace : {
   marginBottom : "18px"
  },
  top : {
    marginTop: "18px"
  },

});

export default TableDrawer;
