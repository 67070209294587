      import {
        fetchingData,
        fetchDataFailure,
        fetchDataSuccess,
        fetchMeta
      } from "../data/dataSlice";
      import {
        fetchChartFailure,
        fetchPopularityChartSuccess,
        fetchComparisonChartSuccess,
        fetchApplicationRateChartSuccess,
        fetchingChart,
      } from "../data/chartSlice";
      import { authHeader } from "../_helpers/auth-header";
      import moment from "moment";

      const baseURL = process.env.REACT_APP_API_BASE_URL;

      

      const fetchCandidates = (queryParams) => async (dispatch) => {

        dispatch(fetchingData());

        const requestOptions = {
          method: "GET",
          headers: authHeader(),
          //body: params
        };
        
        try {
          const fetchdata = await fetch(`${baseURL}/candidates/download`, requestOptions);
          const response = await fetchdata.json();
          const { success, data, meta } = response;
          if (success) {
            await dispatch(fetchDataSuccess(data));
            await dispatch(fetchMeta(meta.pagination.total_entries));
          } else {
            dispatch(fetchDataFailure());
          }
        } catch (error) {}
      };

      const fetchChartsData = () => async (dispatch) => {
        
         dispatch(fetchingChart());

         const requestOptions = {
          method: "GET",
          headers: authHeader(),
          //body: params
        };
        
        try {
          const responseOne = await fetch(`${baseURL}/reports/daily-application-rate`, requestOptions);
          const responseTwo = await fetch(`${baseURL}/reports/comparison-charts`, requestOptions);
          const responseThree = await fetch(`${baseURL}/reports/popularity-chart`, requestOptions);

           const applicationRateChart = await responseOne.json();
           const comparisonChart = await responseTwo.json();
           const popularityChart = await responseThree.json();

           if(applicationRateChart.success && comparisonChart.success && popularityChart.success){

             const chart1 = applicationRateChart.data;
             //sort array by date before putting in store
             chart1.sort((a, b) => moment(a.registrationDate).diff(moment(b.registrationDate)));
             //dispatch data to store
             await dispatch(fetchApplicationRateChartSuccess(chart1));
             const chart2 = comparisonChart.data;
             //dispatch data to store
             await dispatch(fetchComparisonChartSuccess(chart2));
             const chart3 = popularityChart.data;
             //dispatch data to store
             await dispatch(fetchPopularityChartSuccess(chart3));
           }
        } catch (error) {
          dispatch(fetchChartFailure());
        }
      }

      export const dataActions = {
        fetchCandidates,
        fetchChartsData
      };
