import { createSlice } from "@reduxjs/toolkit";

let user = localStorage.getItem("user");
const initUser = user ? { authenticated: true, loading: false, user } : {};

const authSlice = createSlice({
  name: "auth",
  initialState: initUser,
  reducers: {
    loginRequest: (state) => {
      state.authenticated = false;
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.user = action.payload;
      state.authenticated = true;
      state.loading = false;
    },
    loginFailure: (state) => {
      state.authenticated = false;
      state.loading = false;
    },
    logout: (state) => {
      state.authenticated = false;
    },
    passwordResetRequest: (state) => {
      state.resettingPassword = false;
      state.passwordIsReset = false
      state.loading = true;
    },
    passwordResetSuccess: (state) => {
      state.resettingPassword = true;
      state.loading = false;
    },
    passwordResetFailure: (state) => {
      state.resettingPassword = false;
      state.passwordIsReset = false
      state.loading = false;
    },
    passwordIsReset: (state) => {
      state.passwordIsReset = true
    }
  },
});

export const {
  loginSuccess,
  loginFailure,
  loginRequest,
  logout,
  passwordResetRequest,
  passwordResetSuccess,
  passwordResetFailure,
  passwordIsReset
} = authSlice.actions;

export default authSlice.reducer;
