import React from 'react';
import Chart from "react-apexcharts";
import { useSelector } from 'react-redux';
import moment from "moment";

const DailyApplicationCharts = () => {
    
    const { applicationRateChart } = useSelector((state) => state.chart);
    //Process data to be suitable for use in the DailyApplicationCharts
    //Number of registered users per day is to be displayed on the chart
    let registeredUsers = [];
    let dates = [];

    try {
        applicationRateChart.forEach(value => {
        registeredUsers.push(value.noRegistered);
        dates.push(moment(value.registrationDate).format("MMM DD"));
    });
    } catch (error) {
        
    }

    const series = [
        {
           name: "Applicants",
           data: registeredUsers
        }
    ];

    const options = {
        chart: {
            events: {
            beforeZoom: function(ctx) {
                ctx.w.config.range = undefined
            }
            },
            height: 200,
            type: "line",
            zoom: {
                enabled: true
            },
            animations: {
                enabled: true,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
            },
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: true,
                  selection: false,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: false,
                  reset: false,
                },
            },    
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 2,
            curve: "straight"
        },
        colors: ['#93278F'],
        title: {
            text: "Daily Application Rate",
            align: "left"
        },
        xaxis: {
            range: 12,
            categories: dates,
            labels: {
                hideOverlappingLabels: true,
            }
        },
        noData: {
            text: 'Loading...'
        }
    }
    return (
        <div>
           <Chart options={options} series={series} type="line" height={300}/>
        </div>
    )
}

export default DailyApplicationCharts
