import React, { Fragment } from 'react'
import ReportsMainArea from './ReportsMainArea'
import ReportCharts from './charts/ReportCharts'

const ReportsTab = () => {
    return (
       <Fragment>
           <ReportsMainArea/>
         <ReportCharts/>
       </Fragment>
    )
}

export default ReportsTab;
